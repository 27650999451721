import { ActionTree } from "vuex";
import { CategoriesState, Category } from "@/store/categories/types";
import { RootState } from "@/store/types";
import { Categories } from "@/api/categories";

export const actions: ActionTree<CategoriesState, RootState> = {
  async fetchCategories({ commit }, showDisabled = false) {
    try {
      commit("loading");
      const result = await Categories.fetchCategories(
        undefined,
        -1,
        showDisabled
      );
      commit("setCategories", result.data.results);
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  async createCategory({ commit, dispatch, rootState }, categoryData) {
    try {
      commit("loading");
      categoryData.tagType = { name: "menu" };
      categoryData.organisation = { id: rootState.shops.activeShop.id };
      await Categories.createCategory(categoryData);

      dispatch("fetchCategories");
      commit("notifications/success", `Category ${categoryData.name} created`, {
        root: true,
      });
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  async updateCategory({ commit, dispatch, rootState }, category: Category) {
    try {
      commit("loading");
      await Categories.updateCategory(category);
      dispatch("fetchCategories");
      commit("notifications/success", `Category ${category.name} updated`, {
        root: true,
      });
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  async deleteCategory({ commit, dispatch }, category: Category) {
    try {
      commit("loading");
      await Categories.deleteCategory(category.id);

      dispatch("fetchCategories");
      commit("notifications/success", `Category ${category.name} deleted`, {
        root: true,
      });
    } catch (error) {
      dispatch(
        "notifications/error",
        { message: "Could not delete category", error },
        { root: true }
      );
    }
  },

  async disableCategory(
    { commit, dispatch },
    data: { category: Category; showDisabled: boolean }
  ) {
    try {
      commit("loading");
      await Categories.disableCategory(data.category.id, data.category.enabled);
      dispatch("fetchCategories", data.showDisabled);
      let msg = data.category.enabled
        ? `Category ${data.category.name} disabled`
        : `Category ${data.category.name} enabled`;
      commit("notifications/success", msg, { root: true });
    } catch (error) {
      dispatch(
        "notifications/error",
        { message: "Could not delete category", error },
        { root: true }
      );
    }
  },

  paginate({ commit }, pagination) {
    commit("paginate", pagination);
  },
};
