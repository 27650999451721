import { Module } from "vuex";
import { getters } from "@/store/categories/getters";
import { actions } from "@/store/categories/actions";
import { mutations } from "@/store/categories/mutations";
import { CategoriesState } from "@/store/categories/types";
import { RootState } from "@/store/types";
import { pagingDefault } from "../utils";

export const state: CategoriesState = {
  categories: [],
  pagination: pagingDefault,
  loading: false,
};

const namespaced: boolean = true;

export const categories: Module<CategoriesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
