import { MutationTree } from "vuex";
import { CategoriesState } from "@/store/categories/types";

export const mutations: MutationTree<CategoriesState> = {
  setCategories(state, payload) {
    state.categories = payload;
    state.loading = false;
  },

  loading(state) {
    state.loading = true;
  },

  /* Paging */
  paginate(state, payload) {
    state.pagination = payload;
  },
  setTotal(state, payload) {
    state.pagination.totalItems = payload;
  },
};
